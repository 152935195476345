import { experimental_extendTheme as extendTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    keeneeLightBlue: {
      main: string;
    };
  }
}

// Paste MUI Sync Figma theme overrides
const keeneeTheme = extendTheme({
  spacing: 8,
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#3B5DF8",
          dark: "#0034E8",
          light: "#4E63F6",
          // _states: {
          //   hover: "rgba(59, 93, 248, 0.04)",
          //   selected: "rgba(59, 93, 248, 0.08)",
          //   focus: "rgba(59, 93, 248, 0.12)",
          //   focusVisible: "rgba(59, 93, 248, 0.3)",
          //   outlinedBorder: "rgba(59, 93, 248, 0.5)",
          // },
        },
        secondary: {
          main: "#FFB947",
          dark: "#FFA91F",
          light: "#FFC870",
          contrastText: "rgba(0, 0, 0, 0.77)",
          // _states: {
          //   hover: "rgba(255, 185, 71, 0.08)",
          //   selected: "rgba(255, 185, 71, 0.16)",
          //   focus: "rgba(255, 185, 71, 0.2)",
          //   focusVisible: "rgba(255, 185, 71, 0.4)",
          //   outlinedBorder: "rgba(255, 185, 71, 0.65)",
          // },
        },
        keeneeLightBlue: {
          main: "#f0f4f9",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: "#9CA2FB",
          dark: "#4E63F6",
          light: "#E8E9FE",
          // _states: {
          //   hover: "rgba(59, 93, 248, 0.08)",
          //   selected: "rgba(59, 93, 248, 0.16)",
          //   focus: "rgba(59, 93, 248, 0.12)",
          //   focusVisible: "rgba(59, 93, 248, 0.3)",
          //   outlinedBorder: "rgba(59, 93, 248, 0.5)",
          // },
        },
        secondary: {
          main: "#FFD085",
          dark: "#FFC05C",
          light: "#FFEFD6",
          // _states: {
          //   hover: "rgba(255, 185, 71, 0.08)",
          //   selected: "rgba(255, 185, 71, 0.16)",
          //   focus: "rgba(255, 185, 71, 0.12)",
          //   focusVisible: "rgba(255, 185, 71, 0.3)",
          //   outlinedBorder: "rgba(255, 185, 71, 0.5)",
          // },
        },
        keeneeLightBlue: {
          main: "#f0f4f9",
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: "4rem",
      lineHeight: "72px",
    },
    h2: {
      fontSize: "3rem",
      lineHeight: "56px",
    },
    h3: {
      fontSize: "2.5rem",
      lineHeight: "48px",
    },
    h4: {
      fontSize: "2rem",
      lineHeight: "40px",
    },
    h5: {
      lineHeight: "32px",
    },
    h6: {
      fontSize: "1rem",
      lineHeight: "24px",
    },
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 16,
          "&:last-child": {
            paddingBottom: 16,
          },
        },
      },
    },
  },
});

export { keeneeTheme };
