import { Box, TextField, MenuItem, Grid, Button } from "@mui/material";
import moment from "moment";
import React, { useMemo } from "react";

interface MonthData {
  value: string;
  label: string;
}

interface AvailabilityMonthSelectorProps {
  monthYear: string;
  onMonthSelected: (monthYear: string) => void;
}

export const AvailabilityMonthSelector: React.FC<
  AvailabilityMonthSelectorProps
> = ({ onMonthSelected, monthYear }) => {
  const monthsInPast = 12;
  const monthsInFuture = 6;

  const startDate = moment().startOf("month").subtract(monthsInPast, "months");
  const months = useMemo(() => {
    const res = [];
    for (let i = 0; i < monthsInPast + monthsInFuture; i++) {
      const currentDate = startDate.add(1, "months");
      res.push({
        value: `${currentDate.format("M.YYYY")}`.toLowerCase(),
        label: `${currentDate.format("MMM, YYYY")}`,
      });
    }
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        display: "flex",
        flexDirection: "row",
      }}
      gap={2}
    >
      {/* {monthYear && months.length > 0 && (
        <TextField
          select
          label="Starting month"
          size="small"
          value={monthYear}
          disabled={true}
          sx={{ flex: "1 1 auto", maxWidth: "250px" }}
          onChange={(event) => {
            onMonthSelected(event.target.value);
          }}
        >
          {months.map((month: MonthData) => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </TextField>
      )} */}
    </Box>
  );
};
