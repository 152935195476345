import { Box, Grid } from "@mui/material";
import { Listing } from "../types";
import { ListingPricePoint } from "./ListingPricePoint";

interface ListingCardPricingProps {
  listing: Listing;
}

export const ListingCardPricing: React.FC<ListingCardPricingProps> = ({
  listing,
}) => {
  return (
    <Grid container xs={12} sx={{ justifyContent: "flex-start", gap: 2 }}>
      {(listing.pricingType === "quoted" ||
        listing.pricingType === "contact_owner") && (
        <Grid item xs={12}>
          <ListingPricePoint period="Quoted" />
        </Grid>
      )}
      {listing.pricingType === "time_based" &&
        listing.rentalTimingMode === "by_hour" && (
          <Grid item xs={12}>
            <ListingPricePoint cost={listing.prices.perHour} period="Hour" />
          </Grid>
        )}
      {listing.pricingType === "time_based" &&
        listing.rentalTimingMode === "by_day" &&
        ["perDay", "perWeek", "perMonth", "perSeason"].map(
          (periodLabel) =>
            periodLabel in listing.prices && (
              <Box sx={{ display: "flex" }}>
                <ListingPricePoint
                  cost={
                    listing.prices[periodLabel as keyof typeof listing.prices]
                  }
                  period={periodLabel.replace("per", "")}
                />
              </Box>
            ),
        )}
      {listing.pricingType === "one_off_fee" && (
        <Grid item xs={12}>
          <ListingPricePoint cost={listing.prices.oneOffFee} period="One off" />
        </Grid>
      )}
    </Grid>
  );
};
