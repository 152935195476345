import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Button,
  IconButton,
  Menu,
  styled,
  ButtonProps,
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import moment from "moment";
import React, { useMemo, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { grey } from "@mui/material/colors";
import { NewBookingDialog } from "./NewBookingDialog";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(grey[200]),
  minWidth: 0,
  padding: 6,
  backgroundColor: grey[200],
  "&:hover": {
    backgroundColor: grey[500],
  },
}));

interface AvailabilityActionsProps {
  onChangeShowStock: (newShowStock: boolean) => void;
}

export const AvailabilityActions: React.FC<AvailabilityActionsProps> = ({
  onChangeShowStock,
}) => {
  const [viewMode, setViewMode] = useState("stock");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        display: "flex",
        flexDirection: "row",
      }}
      gap={2}
    >
      <ToggleButtonGroup
        color="info"
        size="small"
        value={viewMode}
        exclusive
        onChange={(event, newValue) => {
          setViewMode(newValue);
          onChangeShowStock(newValue === "stock");
        }}
        aria-label="Platform"
      >
        <ToggleButton value="stock">Stock</ToggleButton>
        <ToggleButton value="orderds">Orders</ToggleButton>
      </ToggleButtonGroup>
      <Button variant="contained" onClick={handleClick}>
        New reservation
      </Button>
      {open && (
        <NewBookingDialog
          isOpen={open}
          handleCancelled={handleClose}
          handleOrderCreated={() => {
            handleClose();
          }}
        />
      )}
    </Box>
  );
};
