import { Grid, InputAdornment, MenuItem, TextField } from "@mui/material";
import React, { useEffect } from "react";
import {
  UseFormRegister,
  FieldErrors,
  UseFormWatch,
  UseFormUnregister,
} from "react-hook-form";
import { ListingEditFormValues } from "./ListingEditDialog";

interface ListingEditAdditionalDetailsProps {
  register: UseFormRegister<ListingEditFormValues>;
  unregister: UseFormUnregister<ListingEditFormValues>;
  errors: FieldErrors<ListingEditFormValues>;
  watch: UseFormWatch<ListingEditFormValues>;
}

export const ListingEditAdditionalDetails: React.FC<
  ListingEditAdditionalDetailsProps
> = ({ errors, register, unregister, watch }) => {
  const transferType = watch("additionalDetails.transferType");
  const radiusType = watch("additionalDetails.radiusType");

  useEffect(() => {
    if (transferType === "pickup_or_delivery") {
      register("additionalDetails.deliveryFee");
    } else {
      unregister("additionalDetails.deliveryFee");
    }
  }, [register, unregister, transferType]);

  useEffect(() => {
    if (radiusType === "radius_in_miles") {
      register("additionalDetails.availabilityRadius");
    } else {
      unregister("additionalDetails.availabilityRadius");
    }
  }, [register, unregister, radiusType]);

  return (
    <Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", flexDirection: "column", pt: 2, pb: 2 }}
        gap={4}
      >
        <TextField
          select
          label="Pickup or Delivery"
          size="small"
          value={watch("additionalDetails.transferType")}
          inputProps={register("additionalDetails.transferType")}
          error={!!errors.additionalDetails?.transferType}
          helperText={errors.additionalDetails?.transferType?.message}
        >
          <MenuItem key="pickup" value="pickup">
            Pickup
          </MenuItem>
          <MenuItem key="pickup_or_delivery" value="pickup_or_delivery">
            Pickup Or Delivery
          </MenuItem>
        </TextField>

        {transferType === "pickup_or_delivery" && (
          <TextField
            size="small"
            type="number"
            label="Delivery fee per mile"
            value={watch("additionalDetails.deliveryFee")}
            inputProps={register("additionalDetails.deliveryFee")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            error={!!errors.additionalDetails?.deliveryFee}
            helperText={errors.additionalDetails?.deliveryFee?.message}
          />
        )}

        <TextField
          select
          label="Distance you want to cover"
          size="small"
          value={watch("additionalDetails.radiusType")}
          inputProps={register("additionalDetails.radiusType")}
          error={!!errors.additionalDetails?.radiusType}
          helperText={errors.additionalDetails?.radiusType?.message}
        >
          <MenuItem key="unlimited" value="unlimited">
            Unlimited
          </MenuItem>
          <MenuItem key="radius_in_miles" value="radius_in_miles">
            Radius in miles
          </MenuItem>
        </TextField>

        {radiusType === "radius_in_miles" && (
          <TextField
            size="small"
            type="number"
            label="Distance in miles"
            value={watch("additionalDetails.availabilityRadius")}
            inputProps={register("additionalDetails.availabilityRadius")}
            error={!!errors.additionalDetails?.availabilityRadius}
            helperText={errors.additionalDetails?.availabilityRadius?.message}
          />
        )}
      </Grid>
    </Grid>
  );
};
