import { Box } from "@mui/material";
import {
  ConversationMessage as ConversationMessageType,
  UserPublicInformation,
} from "../types";
import { ConversationMessage } from "./ConversationMessage";

interface ConversationMessageStreamProps {
  replier: UserPublicInformation | undefined;
  conversationMessages: ConversationMessageType[] | undefined;
}

export const ConversationMessageStream: React.FC<
  ConversationMessageStreamProps
> = ({ conversationMessages, replier }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column-reverse",
        padding: 2,
        flex: "1 1 0px",
        overflow: "auto",
      }}
      gap={2}
    >
      {conversationMessages &&
        replier &&
        conversationMessages.map((message) => (
          <ConversationMessage message={message} replier={replier} />
        ))}
    </Box>
  );
};
