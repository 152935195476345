import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import keeneeLogo from "../../keenee-logo.svg";
import { ErrorOutline } from "@mui/icons-material";
import UserService from "../../services/UserService";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { Alert, Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export function Login() {
  const navigate = useNavigate();
  const { returnUrl } = useLoaderData() as { returnUrl: string };

  type FormValues = {
    email: string;
    password: string;
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("E-mail is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setError, formState } =
    useForm<FormValues>(formOptions);
  const { errors } = formState;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    let userProfileData;
    try {
      userProfileData = await UserService.login(data.email, data.password);
      navigate(returnUrl);
    } catch (error) {
      let message = "Unknown Error during login";
      if (error instanceof Error) {
        message = error.message;
      }
      setError("root", { message: message });
      return;
    }

    if (userProfileData) {
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      maxWidth="400px"
      spacing={4}
    >
      <Grid item xs={12}>
        <Box textAlign={"center"}>
          <img src={keeneeLogo} alt="Keenee Logo" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="body1" gutterBottom align="center">
            If you are an approved Keenee Business Vendor, please login using
            the form below.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
            <TextField
              label="E-mail"
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <TextField
              type="password"
              label="Password"
              {...register("password")}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            {errors.root && (
              <Alert
                icon={<ErrorOutline fontSize="inherit" />}
                severity="error"
              >
                {errors.root.message}
              </Alert>
            )}
            <LoadingButton
              variant="contained"
              type="submit"
              loading={formState.isSubmitting}
            >
              <span>Log in</span>
            </LoadingButton>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="body1" align="center">
            To become a Keenee Business Vendor, please{" "}
            <Link to="/registration">register here</Link>.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
