import { Box, Grid, Paper, Typography } from "@mui/material";
import { Conversation, ConversationMessage } from "../types";
import moment from "moment";
import _ from "lodash";
import { UserAvatar } from "./UserAvatar";

interface ConversationBoxProps {
  conversation: Conversation;
  onSelectConversation: (conversationId: string) => void;
  isSelected: boolean;
  read: boolean;
}

const getLatestReplyDate = (messages: ConversationMessage[]) =>
  _.sortBy(messages.map((message) => moment(message.date).unix()))
    .slice(-1)
    .pop();

export const ConversationBox: React.FC<ConversationBoxProps> = ({
  conversation,
  isSelected,
  onSelectConversation,
  read,
}) => {
  const latestReplyDate = getLatestReplyDate(conversation.messages);

  return (
    <Paper
      elevation={0}
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        padding: 2,
        borderRadius: 0,
        cursor: "pointer",
        backgroundColor: isSelected ? "keeneeLightBlue.main" : "transparent",
      }}
      onClick={() => onSelectConversation(conversation.id)}
    >
      <Box>
        <Grid container xs={12}>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UserAvatar user={conversation.replier} />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
            }}
          >
            <Typography variant="body1" fontWeight={read ? "normal" : "700"}>
              {conversation.replier.username}
            </Typography>
            <Typography variant="body1" fontWeight={read ? "normal" : "700"}>
              {conversation.listing ? conversation.listing.name : "N/A"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="body2">
              {latestReplyDate ? moment.unix(latestReplyDate).fromNow() : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
