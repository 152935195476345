import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Listing } from "../types";
import Carousel from "react-material-ui-carousel";
import { ListingCardPricing } from "./ListingCardPricing";

interface ListingCardProps {
  sx?: SxProps<Theme>;
  listing: Listing;
  onEditListing: (listingId: string) => void;
}

export const EmptyImage = () => (
  <Box
    sx={{
      width: "100%",
      backgroundColor: "gray",
      aspectRatio: "1/1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  ></Box>
);

export const ListingCard: React.FC<ListingCardProps> = ({
  listing,
  onEditListing,
}) => (
  <Card>
    <CardMedia
      sx={{
        width: "100%",
        aspectRatio: "1/1",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Carousel animation="slide" autoPlay={false} indicators={false}>
        {listing.imagesMeta ? (
          listing.imagesMeta.map((imageMeta) =>
            imageMeta.url ? (
              <Box
                component="img"
                src={imageMeta.url}
                sx={{
                  width: "100%",
                  backgroundColor: "gray",
                  aspectRatio: "1/1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : (
              <EmptyImage />
            ),
          )
        ) : (
          <EmptyImage />
        )}
      </Carousel>
    </CardMedia>
    <CardContent sx={{ pb: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="div" noWrap={true}>
            {listing.name}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: "-webkit-box",
              minHeight: "4rem",
              lineClamp: 3,
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {listing.description.slice(0, 200)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {listing.userSelectableOptions &&
            listing.userSelectableOptions.length > 0
              ? `${listing.userSelectableOptions.length} option${listing.userSelectableOptions.length > 1 ? "s" : ""}`
              : "No options"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ListingCardPricing listing={listing} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </CardContent>
    <CardActions sx={{ textAlign: "center" }}>
      <Grid container>
        <Grid item xs={12}>
          <Button
            disabled={listing.status === "edited_draft"}
            onClick={() => {
              onEditListing(listing.id);
            }}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  </Card>
);
