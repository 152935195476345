import { Chip } from "@mui/material";
import { DeliveryType } from "../types";

export const DeliveryChip = ({
  deliveryType,
}: {
  deliveryType: DeliveryType;
}) => (
  <Chip
    color={deliveryType === "self_pickup" ? "default" : "warning"}
    label={deliveryType === "self_pickup" ? "No" : "Yes"}
  />
);
