import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Listing } from "../types";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ListingEditProductInfo } from "./ListindEditProductInfo";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { useEditListingMutation } from "../store/apiSlice";
import { ListingEditAdditionalDetails } from "./ListindEditAdditionalDetails";
import _ from "lodash";
import { ListingEditPricing } from "./ListindEditPricing";
import { ListingEditImages } from "./ListingEditImages";

export interface ListingEditDialogProps {
  listing: Listing;
  handleClose: () => void;
}

export const RentalTimingMode: Record<
  NonNullable<Listing["rentalTimingMode"]>,
  Listing["rentalTimingMode"]
> = {
  by_hour: "by_hour",
  by_day: "by_day",
};

export type ListingEditFormValues = {
  details: {
    name: string;
    make: string;
    description: string;
    limitations?: string;
    zipCode: string;
  };
  additionalDetails: {
    radiusType: string;
    availabilityRadius?: number;
    transferType: string;
    deliveryFee?: number;
  };
  images: string[];
  pricing: {
    pricingType: string;
    rentalTimingMode?: string | null | undefined;
    perHour?: number | undefined;
    perDay?: number | undefined;
    perWeek?: number | undefined;
    perMonth?: number | undefined;
    perSeason?: number | undefined;
    oneOffFee?: number | undefined;
  };
};

export const ListingEditDialog: React.FC<ListingEditDialogProps> = ({
  handleClose,
  listing,
}) => {
  const [selectedTab, setSelectedTab] = useState("product_info");

  const handleChange = (event: React.SyntheticEvent, newTab: string) => {
    setSelectedTab(newTab);
  };

  // FORM

  const MAX_ZIP_LENGTH = 5;
  const MIN_RADIUS_DISTANCE = 5;
  const MAX_RADIUS_DISTANCE = 100;

  const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;
  const testTwoDigitsAfterComma = (val?: string | number) =>
    val !== null && val !== undefined
      ? patternTwoDigitsAfterComma.test(val.toString())
      : true;

  const [handleEditListing, { isLoading: isUpdatingListing }] =
    useEditListingMutation();

  // form validation rules
  const validationSchema = yup.object().shape({
    details: yup.object({
      name: yup
        .string()
        .min(5, ({ min }) => `Title must be at least ${min} characters`)
        .max(70, ({ max }) => `Title must be max ${max} characters`)
        .required("Title is required"),
      make: yup
        .string()
        .required("Model is required")
        .max(40, ({ max }) => `Model must be max ${max} characters`),
      description: yup
        .string()
        .min(10, ({ min }) => `Description must be at least ${min} characters`)
        .required("Description is required")
        .max(2500, ({ max }) => `Description must be max ${max} characters`),
      limitations: yup.string(),
      zipCode: yup
        .string()
        .test(
          "is-number",
          `Please enter a number`,
          (zip) =>
            !!(typeof Number(zip) === "number" && zip && /^\d+$/.test(zip)),
        )
        .test(
          "is-proper-length",
          `Please enter ${MAX_ZIP_LENGTH} digits`,
          (zip) => zip?.length === MAX_ZIP_LENGTH,
        )
        .required("Zip is required"),
    }),

    additionalDetails: yup.object({
      radiusType: yup
        .string()
        .required()
        .oneOf(["radius_in_miles", "unlimited"]),
      availabilityRadius: yup
        .number()
        .typeError("Please enter a number")
        .when("radiusType", {
          is: "radius",
          then: () =>
            yup
              .number()
              .typeError("Please enter a number")
              .required("Please provide a value")
              .min(
                MIN_RADIUS_DISTANCE,
                `Radius must be at least ${MIN_RADIUS_DISTANCE} miles`,
              )
              .max(MAX_RADIUS_DISTANCE, "Radius cannot exceed 100 miles"),

          otherwise: () =>
            yup
              .number()
              .typeError("Please enter a number")
              .nullable()
              .notRequired(),
        }),
      transferType: yup
        .string()
        .required()
        .oneOf(["pickup", "pickup_or_delivery"]),
      deliveryFee: yup.number().when("transferType", {
        is: "pickup_or_delivery",
        then: () =>
          yup
            .number()
            .typeError("Please enter a number")
            .min(0, `Delivery fee cannot be negative`)
            .required("Please provide a value"),
        otherwise: () => yup.number().nullable().notRequired(),
      }),
    }),

    images: yup.array(yup.string().required()).required(),
    pricing: yup.object({
      pricingType: yup
        .string()
        .required()
        .oneOf(
          ["free", "one_off_fee", "time_based", "quoted", "contact_owner"],
          "Invalid Pricing Type",
        ),
      rentalTimingMode: yup
        .string()
        .nullable()
        .when("pricingType", {
          is: "time_based",
          then: () =>
            yup
              .string()

              .required("Timing mode is required")
              .oneOf(["by_day", "by_hour"], "Invalid Timing Mode"),
          otherwise: () =>
            yup
              .string()
              .optional()
              .nullable()
              .transform((_: string) => null),
        }),

      perHour: yup
        .number()
        .transform((value) =>
          isNaN(value) ? undefined : value === null ? undefined : value,
        )
        .when("rentalTimingMode", {
          is: "by_hour",
          then: () =>
            yup
              .number()
              .typeError("Price must be a number")
              .positive("Price must be more than $0")
              .test(
                "is-decimal",
                "The amount should be a decimal with maximum two digits after dot",
                testTwoDigitsAfterComma,
              )
              .required("Please provide a value"),
          otherwise: () =>
            yup
              .number()
              .optional()
              .nullable()
              .transform((_: number) => null),
        }),
      perDay: yup
        .number()
        .transform((value) =>
          isNaN(value) ? undefined : value === null ? undefined : value,
        )
        .when("rentalTimingMode", {
          is: "by_day",
          then: () =>
            yup
              .number()
              .positive("Price must be more than $0")
              .typeError("Price must be a number")
              .required("Please provide a value")
              .test(
                "is-decimal",
                "The amount should be a decimal with maximum two digits after dot",
                testTwoDigitsAfterComma,
              ),
          otherwise: () =>
            yup
              .number()
              .optional()
              .nullable()
              .transform((_: number) => {
                console.log("transforming");
                return undefined;
              }),
        }),
      perWeek: yup.number().when("rentalTimingMode", {
        is: "by_day",
        then: () =>
          yup
            .number()
            .transform((value) =>
              isNaN(value) ? undefined : value === null ? undefined : value,
            )
            .optional()
            .positive("Price must be more than $0")
            .moreThan(
              yup.ref("perDay"),
              "Weekly price must be higher than the daily price",
            )
            .test(
              "is-decimal",
              "The amount should be a decimal with maximum two digits after dot",
              testTwoDigitsAfterComma,
            )
            .nullable(),
      }),
      perMonth: yup.number().when("rentalTimingMode", {
        is: "by_day",
        then: () =>
          yup
            .number()
            .transform((value) =>
              isNaN(value) ? undefined : value === null ? undefined : value,
            )
            .positive("Price must be more than $0")
            .optional()
            .when("perDay", {
              is: (perDay: number) => perDay > 0,
              then: () =>
                yup
                  .number()
                  .moreThan(
                    yup.ref("perDay"),
                    "Monthly price must be higher than the daily price",
                  ),
            })
            .when("perWeek", {
              is: (perWeek: number) => perWeek > 0,
              then: () =>
                yup
                  .number()
                  .moreThan(
                    yup.ref("perWeek"),
                    "Monthly price must be higher than the weekly price",
                  ),
            })
            .test(
              "is-decimal",
              "The amount should be a decimal with maximum two digits after dot",
              testTwoDigitsAfterComma,
            )
            .nullable(),
      }),
      perSeason: yup.number().when("rentalTimingMode", {
        is: "by_day",
        then: () =>
          yup
            .number()
            .transform((value) =>
              isNaN(value) ? undefined : value === null ? undefined : value,
            )
            .positive("Price must be more than $0")
            .optional()
            .when("perWeek", {
              is: (perWeek: number) => perWeek > 0,
              then: () =>
                yup
                  .number()
                  .moreThan(
                    yup.ref("perWeek"),
                    "Season price must be higher than the weekly price",
                  ),
            })
            .when("perMonth", {
              is: (perMonth: number) => perMonth > 0,
              then: () =>
                yup
                  .number()
                  .moreThan(
                    yup.ref("perMonth"),
                    "Season price must be higher than the monthly price",
                  ),
            })
            .test(
              "is-decimal",
              "The amount should be a decimal with maximum two digits after dot",
              testTwoDigitsAfterComma,
            )
            .nullable(),
      }),

      oneOffFee: yup
        .number()
        .transform((value) =>
          isNaN(value) ? undefined : value === null ? undefined : value,
        )
        .when("pricingType", {
          is: "one_off_fee",
          then: () =>
            yup
              .number()

              .positive("Price must be more than $0")
              .typeError("Price must be a number")
              .test(
                "is-decimal",
                "The amount should be a decimal with maximum two digits after dot",
                testTwoDigitsAfterComma,
              )
              .required("Please provide a value"),
        }),
    }),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      details: {
        ...listing,
      },
      additionalDetails: {
        ...listing,
        radiusType:
          !listing ||
          listing.availabilityRadius === undefined ||
          listing.availabilityRadius > 1000
            ? "unlimited"
            : "radius_in_miles",
        deliveryFee:
          listing.deliveryPricePerDistanceUnit === null
            ? 0
            : parseFloat(listing.deliveryPricePerDistanceUnit),
        transferType: listing.deliveryOptions?.includes("delivery")
          ? "pickup_or_delivery"
          : "pickup",
      },
      images: listing.images || [],
      pricing: {
        pricingType: listing.pricingType as string,
        rentalTimingMode: listing.rentalTimingMode as string | null,
        ..._.mapValues(listing.prices, (price) =>
          price !== undefined ? parseFloat(price) : undefined,
        ),
      },
    },
  };

  const {
    register,
    unregister,
    handleSubmit,
    setError,
    formState,
    watch,
    setValue,
  } = useForm<ListingEditFormValues>(formOptions);
  const { errors } = formState;

  const onSubmit: SubmitHandler<ListingEditFormValues> = async (data) => {
    try {
      console.log("Form data: ", data, formState.dirtyFields);
      if (listing) {
        const requestData: Pick<Listing, "id"> & Partial<Listing> = {
          id: listing.id,
        };
        const verbatimFields = [
          "details.name",
          "details.make",
          "details.description",
          "details.limitations",
          "details.zipCode",
        ];

        verbatimFields.forEach((fieldPath) => {
          const isFieldDirty = _.get(formState.dirtyFields, fieldPath, false);
          if (isFieldDirty) {
            console.log(`${fieldPath} is dirty`);
            const listingFieldName = fieldPath.split(".").slice(-1);
            _.update(requestData, listingFieldName, _.get(data, fieldPath));
          }
        });

        // Handle radius changes
        if (formState.dirtyFields.additionalDetails?.radiusType) {
          if (data.additionalDetails.radiusType === "unlimited") {
            requestData.availabilityRadius = 1000000;
          } else {
            requestData.availabilityRadius =
              data.additionalDetails.availabilityRadius;
          }
        }

        // Handle delivery changes
        if (formState.dirtyFields.additionalDetails?.transferType) {
          if (data.additionalDetails.transferType === "pickup_or_delivery") {
            requestData.deliveryOptions = ["self_pickup", "delivery"];
            requestData.deliveryPricePerDistanceUnit =
              data.additionalDetails.deliveryFee?.toFixed(2);
          } else {
            requestData.deliveryOptions = ["self_pickup"];
          }
        }

        // Handle pricing changes
        if (formState.dirtyFields.pricing) {
          requestData.prices = _.mapValues(
            _.pick(data.pricing, [
              "perHour",
              "perDay",
              "perWeek",
              "perMonth",
              "perSeason",
              "oneOffFee",
            ]),
            (price) => price?.toString(),
          );
        }

        if (formState.dirtyFields.images) {
          requestData.images = data.images;
        }

        console.log("Request Data:", requestData);
        await handleEditListing(requestData).unwrap();
      }

      // Close the modal
      handleClose();
    } catch (error) {
      let message = "An error occurred when updating data.";
      if (error instanceof Error) {
        message = error.message;
      }
      setError("root", { message: message });
      return;
    }
  };
  // END FORM

  return (
    <Dialog onClose={handleClose} open={!!listing}>
      <TabContext value={selectedTab}>
        <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item padding={2}>
              <Typography variant="h5">Edit Listing</Typography>
            </Grid>

            <Grid item xs={12} sx={{ pl: 2, pr: 2 }}>
              <TabList onChange={handleChange}>
                <Tab
                  label="Product info"
                  value="product_info"
                  sx={{
                    color: formState.errors.details ? "error.main" : "label",
                  }}
                />
                <Tab
                  label="Images"
                  value="images"
                  sx={{
                    color: formState.errors.images ? "error.main" : "label",
                  }}
                />
                <Tab
                  label="Pricing"
                  value="pricing"
                  sx={{
                    color: formState.errors.pricing ? "error.main" : "label",
                  }}
                />
                <Tab
                  label="Additional details"
                  value="additional"
                  sx={{
                    color: formState.errors.additionalDetails
                      ? "error.main"
                      : "label",
                  }}
                />
              </TabList>
            </Grid>
          </Grid>

          <DialogContent dividers={true}>
            <TabPanel value="product_info" sx={{ padding: 0 }}>
              {listing && (
                <ListingEditProductInfo register={register} errors={errors} />
              )}
            </TabPanel>

            <TabPanel value="images" sx={{ padding: 0 }}>
              <ListingEditImages
                register={register}
                unregister={unregister}
                errors={errors}
                imagesMeta={listing.imagesMeta}
                watch={watch}
                setValue={setValue}
              />
            </TabPanel>
            <TabPanel value="pricing" sx={{ padding: 0 }}>
              <ListingEditPricing
                register={register}
                unregister={unregister}
                errors={errors}
                watch={watch}
              />
            </TabPanel>
            <TabPanel value="additional" sx={{ padding: 0 }}>
              {listing && (
                <ListingEditAdditionalDetails
                  register={register}
                  unregister={unregister}
                  errors={errors}
                  watch={watch}
                />
              )}
            </TabPanel>
          </DialogContent>

          <DialogActions>
            <Grid container>
              {errors.root && (
                <Grid item xs={12} sx={{ pl: 2, pr: 2, pt: 1 }}>
                  <Alert severity="error">{errors.root.message} </Alert>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{ padding: 2, display: "flex", justifyContent: "flex-end" }}
                gap={2}
              >
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!formState.isDirty}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Stack>
      </TabContext>
    </Dialog>
  );
};
