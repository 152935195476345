import { Avatar } from "@mui/material";
import { UserPublicInformation } from "../types";
import { stringAvatar } from "../utils";

interface UserAvatarProps {
  user: UserPublicInformation;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ user }) => {
  return user.avatarImage?.thumbnailUrls &&
    user.avatarImage?.thumbnailUrls.length > 0 ? (
    <Avatar src={user.avatarImage.thumbnailUrls[0] || undefined} />
  ) : (
    <Avatar {...stringAvatar(user.username.toUpperCase())} />
  );
};
