import { Box, Card, CardContent } from "@mui/material";
import { PropsWithChildren } from "react";
import { DataSectionEmpty } from "./DataSectionEmpty";
import { DataSectionError } from "./DataSectionError";
import { DataSectionLoading } from "./DataSectionLoading";

interface DataSectionProps extends PropsWithChildren {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  dataLength: number;
}

export const DataSection: React.FC<DataSectionProps> = ({
  isLoading,
  isSuccess,
  isError,
  dataLength,
  children,
}) => (
  <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }} gap={2}>
    <Card>
      <CardContent>
        {isLoading && <DataSectionLoading />}
        {!isLoading && isSuccess && dataLength === 0 && <DataSectionEmpty />}
        {!isLoading && isError && <DataSectionError />}
        {!isLoading && isSuccess && dataLength > 0 ? children : null}
      </CardContent>
    </Card>
  </Box>
);
