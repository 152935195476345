import { Chip } from "@mui/material";
import { PaymentStatus } from "../types";

export const PaymentStatusChip = ({
  paymentStatus,
}: {
  paymentStatus: PaymentStatus;
}) => (
  <Chip
    size="small"
    color={paymentStatus === "paid_by_customer" ? "success" : "warning"}
    label={paymentStatus === "paid_by_customer" ? "Paid" : "Unpaid"}
  />
);
