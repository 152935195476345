import { createBrowserRouter, redirect } from "react-router-dom";
import { Dashboard } from "./views/Dashboard/Dashboard";
import { DefaultLayout } from "./layouts/Default";
import { AuthLayout } from "./layouts/Auth";
import { Login } from "./views/Login/Login";
import UserService from "./services/UserService";
import { store } from "./store";
import { VendorRegistration } from "./views/VendorRegistration/VendorRegistration";
import { Listings } from "./views/Listings/Listings";
import { Messages } from "./views/Messages/Messages";
import { Availability } from "./components/Availability";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    // For every route load the current user first or redirect to login.
    loader: async () => {
      try {
        const user = await UserService.getCurrentUser();
        if (user) {
          store.dispatch({
            type: "user/saveUserProfile",
            payload: user,
          });
        }
      } catch (error) {
        throw redirect("/login");
      }
      return {};
    },
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "availability",
        element: <Availability />,
      },
      {
        path: "listings",
        element: <Listings />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
        loader: ({ request }) => {
          const url = new URL(request.url);
          const returnUrl = url.searchParams.get("returnUrl") || "/";
          return { returnUrl };
        },
      },
      {
        path: "/logout",
        loader: async () => {
          UserService.logout();
          store.dispatch({
            type: "user/saveUserProfile",
            payload: null,
          });
          return redirect("/login");
        },
      },
      {
        path: "/registration",
        element: <VendorRegistration />,
        loader: async () => {
          let user;
          try {
            user = await UserService.getCurrentUser();
          } catch (error) {
            user = null;
          }
          if (user) {
            throw redirect("/");
          }

          return {};
        },
      },
    ],
  },
]);
