import axios from "axios";
import UserService from "../services/UserService";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Add the access token to each request if present in local storage
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = UserService.getLocalAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Refresh the access token if a non-token request fails with a 401
axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (
      originalConfig.url !== "/users/vendor-token/" &&
      originalConfig.url !== "/users/token/refresh/" &&
      err.response
    ) {
      // Request failed and we're not already retrying
      if (err.response.status === 401 && !originalConfig._retry) {
        // Check if a refresh token is saved in storage
        const refreshToken = UserService.getLocalRefreshToken();
        if (refreshToken) {
          // Set a flag so that we don't retry forever
          originalConfig._retry = true;
          try {
            const rs = await axiosInstance.post("/users/token/refresh/", {
              refresh: UserService.getLocalRefreshToken(),
            });

            // Update both tokens in storage
            const { access: accessToken, refresh: refreshToken } = rs.data;
            UserService.updateLocalAccessToken(accessToken);
            UserService.updateLocalRefreshToken(refreshToken);

            return axiosInstance(originalConfig);
          } catch (_error) {
            // Remove tokens from storage if the request to retry failed
            UserService.logout();
            return Promise.reject(_error);
          }
        }
      }
    }

    return Promise.reject(err);
  },
);

export default axiosInstance;
