import { Box, Typography } from "@mui/material";

export const DataSectionEmpty = () => (
  <Box
    sx={{
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography>Nothing to display</Typography>
  </Box>
);
