import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { ListingOptionSelector } from "./ListingOptionSelector";
import {
  useGetVendorBookingAvailabilitiesQuery,
  useGetVendorStockUnitsQuery,
  usePostVendorReservationMutation,
} from "../store/apiSlice";
import { useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { current } from "@reduxjs/toolkit";
import { defaultFormat, Moment } from "moment";
import _ from "lodash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import PersonIcon from "@mui/icons-material/Person";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { v4 as uuidv4 } from "uuid";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { NumberInput } from "./NumberInput";

interface NewBookingDialogProps {
  isOpen: boolean;
  handleCancelled: () => void;
  handleOrderCreated: () => void;
}

export const NewBookingDialog: React.FC<NewBookingDialogProps> = ({
  isOpen,
  handleOrderCreated,
  handleCancelled,
}) => {
  const [selectedListing, setSelectedListing] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [orderItems, setOrderItems] = useState<Record<string, any>[]>([]);
  const [currentStep, setCurrentStep] = useState<string>("order_items");
  const [orderDates, setOrderDates] = useState<[Moment | null, Moment | null]>([
    null,
    null,
  ]);
  const [clientDetails, setClientDetails] = useState<Record<string, any>>({});
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const {
    data: allStockUnits,
    isLoading: stockUnitsLoading,
    isSuccess: allStockUnitsSucccess,
    isError: allStockUnitsError,
  } = useGetVendorStockUnitsQuery();

  type ClientDetailsFormValues = {
    name: string;
    contact: string;
    notes?: string;
  };
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(200, ({ max }) => `Name must be max ${max} characters`)
      .required("Title is required"),
    contact: yup
      .string()
      .required("Contact is required")
      .max(200, ({ max }) => `Contact must be max ${max} characters`),
    notes: yup.string(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: clientDetails,
  };

  const {
    register,
    unregister,
    handleSubmit,
    setError,
    formState,
    watch,
    setValue,
  } = useForm<ClientDetailsFormValues>(formOptions);
  const { errors } = formState;

  const getListingRepr = (listing: string, option: string) => {
    const listingData = allStockUnits.find(
      (unit: Record<string, any>) => unit.rentalItem === listing,
    );

    console.log("Listing data", allStockUnits, listingData);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          lineHeight: "1rem",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          component="span"
          className="listing-title"
          sx={{
            fontWeight: "bold",
            display: "flex",
            fontSize: "12px",
          }}
        >
          {listingData.rentalItemTitle}
        </Box>
        <Box
          sx={{ display: "flex", fontSize: "12px" }}
          component="span"
          className="listing-option"
        >
          {option}{" "}
        </Box>
      </Box>
    );
  };

  const handleListingUnitSelected = (rentalItemId: string, options: string) => {
    console.log("Selected listing/unit", rentalItemId, options);
    setSelectedListing(rentalItemId);
    setSelectedOption(options);
  };

  const [postVendorReservation, { isLoading: isPostingVendorBooking }] =
    usePostVendorReservationMutation();

  const datesSelected = useMemo(() => {
    return _.every(orderDates, (date) => date !== null);
  }, [orderDates]);

  const {
    data: vendorBookingAvailabilities,
    isFetching: isFetchingVendorBookingAvailabilities,
  } = useGetVendorBookingAvailabilitiesQuery({
    startDateISO: orderDates[0],
    endDateISO: orderDates[1],
    orderItems,
  });

  const handleAddToOrder = (rentalItemId: string, option: string) => {
    // TODO: make sure we selected the right unit
    setOrderItems([
      ...orderItems,
      {
        id: uuidv4(),
        rentalItemId,
        quantity: 1,
        option,
      },
    ]);
    setSelectedListing("");
    setSelectedOption("");
  };

  const handleChangeQuantity = (
    rentalItemId: string,
    option: string,
    newQuantity: number,
  ) => {
    setOrderItems(
      orderItems.map((item) => {
        if (item.rentalItemId === rentalItemId && item.option === option) {
          return {
            ...item,
            quantity: newQuantity,
          };
        } else {
          return {
            ...item,
          };
        }
      }),
    );
  };

  const handleRemoveFromOrder = (itemId: string) => {
    setOrderItems(orderItems.filter((item) => item.id !== itemId));
  };

  const onSubmit: SubmitHandler<ClientDetailsFormValues> = async (data) => {
    setClientDetails(data);
    setCurrentStep("summary");
  };

  const handleCreateOrder = async () => {
    if (orderDates[0] !== null && orderDates[1] !== null) {
      try {
        setIsCreatingOrder(true);
        await postVendorReservation({
          startDate: orderDates[0],
          endDate: orderDates[1],
          clientName: clientDetails.name,
          clientContact: clientDetails.contact,
          notes: clientDetails.notes,
          orderItems: orderItems,
        }).unwrap();
        handleOrderCreated();
      } catch (error) {
        console.error(error);
      } finally {
        setIsCreatingOrder(false);
      }
    }
  };

  return (
    <Dialog onClose={handleCancelled} open={isOpen} maxWidth={false}>
      {currentStep === "order_items" && (
        <Grid container sx={{ width: "60vw", maxWidth: "800px" }}>
          <Grid
            item
            xs={12}
            padding={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Create a New Reservation</Typography>
            <IconButton onClick={handleCancelled}>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12} sx={{ pl: 2, pr: 2, pb: 2 }}>
            <DateRangePicker
              localeText={{ start: "Start", end: "End" }}
              calendars={2}
              disablePast
              defaultValue={orderDates}
              minDate={moment().add(1, "days")}
              onChange={(newValue) => setOrderDates(newValue)}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </Grid>

          <Grid container xs={12} sx={{ pl: 2, pb: 2 }} spacing={2}>
            <Grid item xs={12}>
              <ListingOptionSelector
                allStockUnits={allStockUnits}
                onListingUnitSelected={handleListingUnitSelected}
                isHorizontal
                itemsSelected={orderItems}
                requireCompleteChoice
                handleConfirm={(rentalItemId, option) => {
                  handleAddToOrder(rentalItemId, option);
                }}
              />
            </Grid>
          </Grid>

          {orderItems.length > 0 && (
            <Grid container xs={12} sx={{ pl: 2, pb: 2 }} spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    borderRadius: "10px",
                    border: 1,
                    borderColor: "divider",
                  }}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell component="th">Listing</TableCell>
                        <TableCell component="th">Quantity</TableCell>
                        <TableCell component="th"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderItems.map((item) => {
                        const itemAvailability = vendorBookingAvailabilities
                          ? vendorBookingAvailabilities.find(
                              (bookingAvailability: Record<string, any>) =>
                                bookingAvailability.itemId === item.id,
                            )
                          : {};
                        console.log("VBA", vendorBookingAvailabilities);
                        return (
                          <TableRow
                            key={item.rentalItemId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={{ pt: 1, pb: 1 }}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  {getListingRepr(
                                    item.rentalItemId,
                                    item.option,
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  {isFetchingVendorBookingAvailabilities ? (
                                    <CircularProgress size={15} />
                                  ) : (
                                    <Chip
                                      label={
                                        itemAvailability &&
                                        itemAvailability.available
                                          ? "Available"
                                          : datesSelected
                                            ? "Unavailable"
                                            : "Please select dates"
                                      }
                                      size="small"
                                      color={
                                        itemAvailability &&
                                        itemAvailability.available
                                          ? "primary"
                                          : datesSelected
                                            ? "error"
                                            : "default"
                                      }
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <NumberInput
                                aria-label="Quantity"
                                min={1}
                                max={100}
                                defaultValue={1}
                                disabled={isFetchingVendorBookingAvailabilities}
                                onChange={(event, val) => {
                                  if (val) {
                                    handleChangeQuantity(
                                      item.rentalItemId,
                                      item.option,
                                      val,
                                    );
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell align="right" sx={{ pt: 1, pb: 1 }}>
                              <IconButton
                                aria-label="delete"
                                color="default"
                                onClick={() => {
                                  handleRemoveFromOrder(item.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{ pl: 2, pr: 2, pb: 2, textAlign: "right" }}
            spacing={2}
          >
            <Button
              variant="contained"
              onClick={() => setCurrentStep("client_details")}
              disabled={
                !orderItems.length ||
                !_.every(vendorBookingAvailabilities, (vba) => vba.available) ||
                isFetchingVendorBookingAvailabilities ||
                !_.every(orderDates, (orderDate) => orderDate !== null)
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}

      {currentStep === "client_details" && (
        <Grid container sx={{ width: "60vw", maxWidth: "800px" }}>
          <Stack
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%" }}
          >
            <Grid
              item
              xs={12}
              padding={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Client details</Typography>
              <IconButton onClick={handleCancelled}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                pl: 2,
                pr: 2,
                pb: 2,
                display: "flex",
                flexDirection: "column",
              }}
              gap={2}
            >
              <TextField
                {...register("name")}
                error={!!errors.name}
                label="Client Name"
                fullWidth={true}
                size="small"
                helperText={errors.name?.message}
              />
              <TextField
                {...register("contact")}
                error={!!errors.contact}
                label="Contact"
                fullWidth={true}
                size="small"
                helperText={errors.contact?.message}
              />
              <TextField
                {...register("notes")}
                error={!!errors.notes}
                label="Notes"
                fullWidth={true}
                size="small"
                multiline={true}
                minRows={2}
                maxRows={8}
                helperText={errors.notes?.message}
              />
            </Grid>

            <Grid container xs={12} sx={{ pl: 2, pb: 2 }} spacing={2}></Grid>

            <Grid
              item
              xs={12}
              sx={{
                pl: 2,
                pr: 2,
                pb: 2,
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
              }}
              spacing={2}
            >
              <Button
                variant="outlined"
                onClick={() => setCurrentStep("order_items")}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!formState.isDirty}
              >
                Next
              </Button>
            </Grid>
          </Stack>
        </Grid>
      )}

      {currentStep === "summary" && (
        <Grid container sx={{ width: "60vw", maxWidth: "800px" }}>
          <Grid
            item
            xs={12}
            padding={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Order summary</Typography>
            <IconButton onClick={handleCancelled}>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              pl: 2,
              pr: 2,
              pb: 2,
              display: "flex",
              flexDirection: "column",
            }}
            gap={2}
          >
            <List sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${orderDates[0]?.format("MMM D, YYYY")} - ${orderDates[1]?.format("MMM D, YYYY")}`}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={clientDetails.name} />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <PhoneAndroidIcon />
                </ListItemIcon>
                <ListItemText primary={clientDetails.contact} />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <EditNoteIcon />
                </ListItemIcon>
                <ListItemText primary={clientDetails.notes || "-"} />
              </ListItem>
            </List>
          </Grid>

          <Grid container xs={12} sx={{ pl: 2, pb: 2 }} spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: 1,
                  borderColor: "divider",
                }}
              >
                <Table sx={{ width: "100%" }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">Listing</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderItems.map((item) => (
                      <TableRow
                        key={item.rentalItemId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ pt: 1, pb: 1 }}>
                          {getListingRepr(item.rentalItemId, item.option)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              pl: 2,
              pr: 2,
              pb: 2,
              textAlign: "right",
              display: "flex",
              justifyContent: "space-between",
            }}
            spacing={2}
          >
            <Button
              variant="outlined"
              onClick={() => setCurrentStep("client_details")}
            >
              Back
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => {
                handleCreateOrder();
              }}
              loading={isCreatingOrder}
            >
              Create order
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};
