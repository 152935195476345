import { LoadingButton } from "@mui/lab";
import { Stack, Grid, TextField, SxProps, Theme } from "@mui/material";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { usePostConversationMessageMutation } from "../store/apiSlice";
import { ConversationMessage } from "../types";
import SendIcon from "@mui/icons-material/Send";

interface ConversationMessageFormProps {
  conversationId: string | null;
  onNewMessagePosted: (message: ConversationMessage) => void;
  sx: SxProps<Theme>;
}

export const ConversationMessageForm: React.FC<
  ConversationMessageFormProps
> = ({ conversationId, onNewMessagePosted, sx }) => {
  const [postConversationMessageMutation] =
    usePostConversationMessageMutation();

  type FormValues = {
    message: string;
  };
  const formOptions = {
    defaultValues: {
      message: "",
    },
  };
  const { register, handleSubmit, setError, formState, reset } =
    useForm<FormValues>(formOptions);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      if (conversationId) {
        const newMessage = await postConversationMessageMutation({
          conversationId,
          messageBody: data.message,
        });
        if ("data" in newMessage) {
          onNewMessagePosted(newMessage.data);
        }
      }
      reset({ message: "" }, { keepDirty: false });
    } catch (error) {
      let message = "Unknown Error during registration";
      if (error instanceof Error) {
        message = error.message;
      }
      setError("root", { message: message });
      return;
    }
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} sx={{ ...sx }}>
      <Grid
        item
        sx={{
          display: "flex",
          flexGrow: 0,
          minHeight: 100,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", padding: 2 }}
        >
          <TextField
            {...register("message")}
            fullWidth
            multiline
            disabled={!conversationId || formState.isSubmitting}
            placeholder="Type your message here..."
            maxRows={10}
            variant="standard"
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{ display: "flex", alignItems: "center", padding: 2 }}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            endIcon={<SendIcon />}
            disabled={
              !conversationId || formState.isSubmitting || !formState.isDirty
            }
            loading={formState.isSubmitting}
          >
            <span>Send</span>
          </LoadingButton>
        </Grid>
      </Grid>
    </Stack>
  );
};
