import { Box, Drawer, Tab } from "@mui/material";
import { CurrentVendorOrdersTable } from "./CurrentVendorOrdersTable";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useGetCurrentVendorBookingsQuery } from "../store/apiSlice";
import {
  CurrentVendorBookingDirection,
  VendorBooking,
  VendorOrder,
  VendorOrderWithRepresentativeBooking,
} from "../types";
import { getOrdersFromBookings } from "../utils";
import { useTabs } from "../hooks";
import { DataSection } from "./DataSection";
import { useEffect, useRef, useState } from "react";
import { VendorOrderDetails } from "./VendorOrderDetails";
import _ from "lodash";
import { useAutoRefresh } from "../hooks/useAutoRefresh";

export const CurrentVendorOrders: React.FC<{}> = () => {
  const { currentTab, handleChangeTab } = useTabs("daily_outgoing");

  // Get order data
  const {
    outgoingOrders,
    returningOrders,
    hourlyOrders,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetCurrentVendorBookingsQuery(undefined, {
    selectFromResult: ({ data, isLoading, isError, isSuccess }) => {
      let outgoingOrders: VendorOrderWithRepresentativeBooking[] = [];
      let returningOrders: VendorOrderWithRepresentativeBooking[] = [];
      let hourlyOrders: VendorOrderWithRepresentativeBooking[] = [];

      const orders = data ? getOrdersFromBookings(data) : [];

      orders.forEach((order: VendorOrder) => {
        const dailyGoingOutToday = _.find(
          order.bookings,
          (booking) => booking.isGoingOutToday && !booking.isHourly,
        );
        const dailyReturningToday = _.find(
          order.bookings,
          (booking) => booking.isReturningToday && !booking.isHourly,
        );
        const hourlyToday = _.find(
          order.bookings,
          (booking) => !!booking.isHourly,
        );

        if (dailyGoingOutToday) {
          outgoingOrders.push({
            ...order,
            firstRepresentativeBooking: dailyGoingOutToday,
          });
        }

        if (dailyReturningToday) {
          returningOrders.push({
            ...order,
            firstRepresentativeBooking: dailyReturningToday,
          });
        }

        if (hourlyToday) {
          hourlyOrders.push({
            ...order,
            firstRepresentativeBooking: hourlyToday,
          });
        }
      });

      return {
        isLoading,
        isError,
        isSuccess,
        outgoingOrders,
        returningOrders,
        hourlyOrders,
      };
    },
  });

  const { autoRefresh, setAutoRefresh } = useAutoRefresh();

  // keep a reference to the timer between component refreshes
  let autoRefreshInterval = useRef<NodeJS.Timer | null>(null);

  // 30 sec
  const autoRefreshIntervalMSec = 30000;

  useEffect(() => {
    const setUpAutoRefresh = () => {
      if (autoRefreshInterval.current === null) {
        console.log("Setting up the auto-refresh interval");
        autoRefreshInterval.current = setInterval(() => {
          try {
            refetch();
          } catch (error) {
            console.error("Error refreshing availability", error);
          }
        }, autoRefreshIntervalMSec);
      }
    };
    const cancelAutoRefresh = () => {
      if (autoRefreshInterval.current) {
        console.log("Clearing the auto-refresh interval");
        clearInterval(autoRefreshInterval.current);
        autoRefreshInterval.current = null;
      }
    };

    // if auto-refresh has been disabled cancel the timer
    if (autoRefresh) {
      setUpAutoRefresh();
    } else {
      cancelAutoRefresh();
    }

    // Clear the auto-refresh when the component unmounts
    return () => {
      cancelAutoRefresh();
    };
  }, [autoRefresh]);

  const [openOrderNumber, setOpenOrderNumber] = useState<number | null>(null);

  const toggleDrawer = (newOrderNumber: number | null) =>
    setOpenOrderNumber(newOrderNumber);

  return (
    <TabContext value={currentTab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChangeTab} aria-label="Current Orders">
          <Tab
            label={`Starting today (${outgoingOrders.length})`}
            value={"daily_outgoing"}
          />
          <Tab
            label={`Returning today (${returningOrders.length})`}
            value={"daily_returning"}
          />
          <Tab
            label={`Today's Hourly (${hourlyOrders.length})`}
            value={"hourly"}
          />
        </TabList>
      </Box>

      <TabPanel value={"daily_outgoing"} sx={{ pl: 0, pr: 0 }}>
        <DataSection
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          dataLength={outgoingOrders.length}
        >
          <CurrentVendorOrdersTable
            orders={outgoingOrders}
            isLoading={isLoading}
            onOpenOrder={(orderNumber) => {
              console.log(orderNumber);
              toggleDrawer(orderNumber);
            }}
          />
        </DataSection>
      </TabPanel>

      <TabPanel value={"daily_returning"} sx={{ pl: 0, pr: 0 }}>
        <DataSection
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          dataLength={returningOrders.length}
        >
          <CurrentVendorOrdersTable
            orders={returningOrders}
            isLoading={isLoading}
            onOpenOrder={(orderNumber) => {
              console.log(orderNumber);
              toggleDrawer(orderNumber);
            }}
          />
        </DataSection>
      </TabPanel>

      <TabPanel value={"hourly"} sx={{ pl: 0, pr: 0 }}>
        <DataSection
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          dataLength={hourlyOrders.length}
        >
          <CurrentVendorOrdersTable
            orders={hourlyOrders}
            isLoading={isLoading}
            onOpenOrder={(orderNumber) => {
              console.log(orderNumber);
              toggleDrawer(orderNumber);
            }}
          />
        </DataSection>
      </TabPanel>

      <Drawer
        anchor="right"
        open={openOrderNumber !== null}
        onClose={() => {
          toggleDrawer(null);
        }}
      >
        <VendorOrderDetails
          orderNumber={openOrderNumber}
          onClose={() => {
            toggleDrawer(null);
          }}
        />
      </Drawer>
    </TabContext>
  );
};
