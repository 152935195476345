import { Dialog, Grid, Typography } from "@mui/material";
import React from "react";
import { PercentCrop } from "react-image-crop";
import { ImageCropper } from "./ImageCropper";

interface ImageCropperDialogProps {
  image: HTMLImageElement | null;
  isOpen: boolean;
  onCropCancelled: () => void;
  onCropFinished: (crop: PercentCrop) => void;
}

export const ImageCropperDialog: React.FC<ImageCropperDialogProps> = ({
  onCropCancelled,
  onCropFinished,
  image,
  isOpen,
}) => {
  const handleClose = () => {
    onCropCancelled();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Grid container>
        <Grid item padding={2}>
          <Typography variant="h5">Crop your image</Typography>
        </Grid>

        <Grid item xs={12} sx={{ pl: 2, pr: 2, pb: 2 }}>
          {image && (
            <ImageCropper
              image={image}
              onCropFinished={onCropFinished}
              onCropCancelled={onCropCancelled}
            />
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};
