import { Grid, InputAdornment, MenuItem, TextField } from "@mui/material";
import React, { useEffect } from "react";
import {
  UseFormRegister,
  FieldErrors,
  UseFormWatch,
  UseFormUnregister,
} from "react-hook-form";
import { ListingEditFormValues } from "./ListingEditDialog";

interface ListingEditPricingProps {
  register: UseFormRegister<ListingEditFormValues>;
  unregister: UseFormUnregister<ListingEditFormValues>;
  errors: FieldErrors<ListingEditFormValues>;
  watch: UseFormWatch<ListingEditFormValues>;
}

export const ListingEditPricing: React.FC<ListingEditPricingProps> = ({
  errors,
  register,
  unregister,
  watch,
}) => {
  const pricingType = watch("pricing.pricingType");
  const rentalTimingMode = watch("pricing.rentalTimingMode");

  useEffect(() => {
    if (pricingType === "one_off_fee") {
      register("pricing.oneOffFee");
    } else {
      unregister("pricing.oneOffFee");
    }

    if (pricingType === "time_based") {
      if (rentalTimingMode === "per_hour") {
        register("pricing.perHour");
      } else {
        unregister("pricing.perHour");
      }

      if (rentalTimingMode === "per_day") {
        register("pricing.perDay");
        register("pricing.perWeek");
        register("pricing.perMonth");
        register("pricing.perSeason");
      } else {
        unregister("pricing.perDay");
        unregister("pricing.perWeek");
        unregister("pricing.perMonth");
        unregister("pricing.perSeason");
      }
    } else {
      unregister("pricing.perHour");
      unregister("pricing.perDay");
      unregister("pricing.perWeek");
      unregister("pricing.perMonth");
      unregister("pricing.perSeason");
    }
  }, [register, unregister, pricingType, rentalTimingMode]);

  return (
    <Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", flexDirection: "column", pt: 2, pb: 2 }}
        gap={4}
      >
        <TextField
          select
          label="Pricing type"
          size="small"
          disabled={true}
          value={watch("pricing.pricingType")}
          inputProps={register("pricing.pricingType")}
          error={!!errors.pricing?.pricingType}
          helperText={errors.pricing?.pricingType?.message}
        >
          <MenuItem key="one_off_fee" value="one_off_fee">
            One off
          </MenuItem>
          <MenuItem key="free" value="free">
            Free
          </MenuItem>
          <MenuItem key="quoted" value="quoted">
            Quoted
          </MenuItem>
          <MenuItem key="time_based" value="time_based">
            Time based
          </MenuItem>
          <MenuItem key="contact_owner" value="contact_owner">
            Contact owner
          </MenuItem>
        </TextField>

        {pricingType === "one_off_fee" && (
          <TextField
            size="small"
            type="number"
            label="One off fee"
            value={watch("pricing.oneOffFee")}
            inputProps={register("pricing.oneOffFee")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            error={!!errors.pricing?.oneOffFee}
            helperText={errors.pricing?.oneOffFee?.message}
          />
        )}

        {pricingType === "time_based" && rentalTimingMode === "by_hour" && (
          <TextField
            size="small"
            type="number"
            label="Hourly rate"
            value={watch("pricing.perHour")}
            inputProps={register("pricing.perHour")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            error={!!errors.pricing?.perHour}
            helperText={errors.pricing?.perHour?.message}
          />
        )}

        {pricingType === "time_based" && rentalTimingMode === "by_day" && (
          <TextField
            size="small"
            type="number"
            label="Daily rate"
            value={watch("pricing.perDay")}
            inputProps={register("pricing.perDay")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            error={!!errors.pricing?.perDay}
            helperText={errors.pricing?.perDay?.message}
          />
        )}

        {pricingType === "time_based" && rentalTimingMode === "by_day" && (
          <TextField
            size="small"
            type="number"
            label="Weekly rate"
            value={watch("pricing.perWeek")}
            inputProps={register("pricing.perWeek")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            error={!!errors.pricing?.perWeek}
            helperText={errors.pricing?.perWeek?.message}
          />
        )}

        {pricingType === "time_based" && rentalTimingMode === "by_day" && (
          <TextField
            size="small"
            type="number"
            label="Monthly rate"
            value={watch("pricing.perMonth")}
            inputProps={register("pricing.perMonth")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            error={!!errors.pricing?.perMonth}
            helperText={errors.pricing?.perMonth?.message}
          />
        )}

        {pricingType === "time_based" && rentalTimingMode === "by_day" && (
          <TextField
            size="small"
            type="number"
            label="Full season rate"
            value={watch("pricing.perSeason")}
            inputProps={register("pricing.perSeason")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            error={!!errors.pricing?.perSeason}
            helperText={errors.pricing?.perSeason?.message}
          />
        )}
      </Grid>
    </Grid>
  );
};
