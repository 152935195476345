import axios from "axios";
import api from "../api/axiosInstance";

interface TokenResponse {
  data: {
    access: string;
    refresh: string;
  };
}

interface CurrentUserResponse {
  data: {
    id: string;
    username: string;
    avatarImage: string | null;
  };
}
interface UserProfile {
  id: string;
  username: string;
  avatarImage: string | null;
}

const ACCESS_TOKEN_KEY = "keenee_access_token";
const REFRESH_TOKEN_KEY = "keenee_refresh_token";

export default class UserService {
  static updateLocalAccessToken = (accessToken: string | null): void => {
    if (accessToken) {
      window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    } else {
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    }
  };

  static updateLocalRefreshToken = (refreshToken: string | null): void => {
    if (refreshToken) {
      window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    } else {
      window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    }
  };

  static getLocalAccessToken = (): string | null => {
    return window.localStorage.getItem(ACCESS_TOKEN_KEY);
  };

  static getLocalRefreshToken = (): string | null => {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
  };

  static getCurrentUser = async (): Promise<UserProfile> => {
    try {
      const { data } = await api.get<CurrentUserResponse>("/users/current/");
      return data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new Error("Could not retrieve the user profile");
        } else {
          throw new Error(error.message);
        }
      } else {
        let message = "Unknown Error during user profile retrieval";
        if (error instanceof Error) {
          message = error.message;
        }
        throw new Error(message);
      }
    }
  };

  static registerVendor = async (vendorData: any): Promise<any> => {
    try {
      await api.post<TokenResponse>("/users/register_vendor/", vendorData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.message);
      } else {
        let message = "Unknown Error during registration";
        if (error instanceof Error) {
          message = error.message;
        }
        throw new Error(message);
      }
    }
  };

  static login = async (
    email: string,
    password: string,
  ): Promise<UserProfile> => {
    try {
      this.updateLocalAccessToken(null);
      this.updateLocalRefreshToken(null);

      const { data } = await api.post<TokenResponse>("/users/vendor-token/", {
        email,
        password,
      });

      this.updateLocalAccessToken(data.data.access);
      this.updateLocalRefreshToken(data.data.refresh);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          console.log(error.response?.data);
          if (
            error.response?.data?.errors?.email ===
            "User account does not belong to a business vendor."
          ) {
            throw new Error(
              "This account is not a Keenee Business Vendor account",
            );
          } else {
            throw new Error("Invalid credentials");
          }
        } else {
          throw new Error(error.message);
        }
      } else {
        let message = "Unknown Error during login";
        if (error instanceof Error) {
          message = error.message;
        }
        throw new Error(message);
      }
    }

    try {
      const { data } = await api.get<CurrentUserResponse>("/users/current/");
      console.log("Data:", data);

      return data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new Error("Could not retrieve the user profile");
        } else {
          throw new Error(error.message);
        }
      } else {
        let message = "Unknown Error during user profile retrieval";
        if (error instanceof Error) {
          message = error.message;
        }
        throw new Error(message);
      }
    }
  };

  static logout = (): void => {
    this.updateLocalAccessToken(null);
    this.updateLocalRefreshToken(null);
  };
}
