import {
  Box,
  Button,
  Drawer,
  Grid,
  MenuItem,
  Tab,
  TextField,
} from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import {
  useGetConversationsQuery,
  useGetVendorStockUnitAvailabilityQuery,
  useGetVendorStockUnitsQuery,
  useMarkConversationReadMutation,
} from "../store/apiSlice";
import { ConversationMessage as ConversationMessageType } from "../types";
import { useTabs } from "../hooks";
import { useEffect, useMemo, useState } from "react";

import { ConversationMessageForm } from "./ConversationMessageForm";
import { ConversationMessageStream } from "./ConversationMessageStream";
import { ConversationStream } from "./ConversationStream";
import { DataSectionLoading } from "./DataSectionLoading";
import { DataSectionEmpty } from "./DataSectionEmpty";
import { watch } from "fs";
import { ListingOptionSelector } from "./ListingOptionSelector";
import { AvailabilityMonthSelector } from "./AvailabilityMonthSelector";
import { AvailabilityActions } from "./AvailabilityActions";
import { AvailabilityCalendar } from "./AvailabilityCalendar";
import moment from "moment";
import { VendorOrderDetails } from "./VendorOrderDetails";
import { VendorAnyOrderDetails } from "./VendorAnyOrderDetails";
import { VendorReservationDetails } from "./VendorReservationDetails";

export const Availability: React.FC<{}> = () => {
  const thisMonth = moment().startOf("month");
  const thisMonthValue = `${thisMonth.format("M.YYYY")}`.toLowerCase();
  const [monthYear, setMonthYear] = useState<string>(thisMonthValue);
  const [selectedListing, setSelectedListing] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [showStock, setShowStock] = useState(true);

  const {
    data: allStockUnits,
    isLoading: stockUnitsLoading,
    isSuccess: allStockUnitsSucccess,
    isError: allStockUnitsError,
  } = useGetVendorStockUnitsQuery();

  useEffect(() => {
    console.log("All Stock units:", allStockUnits);
  }, [allStockUnits]);

  const handleMonthSelected = (monthYear: string) => {
    console.log("Selected month:", monthYear);
    setMonthYear(monthYear);
  };

  const handleListingUnitSelected = (rentalItemId: string, options: string) => {
    console.log("Selected listing/unit", rentalItemId, options);
    setSelectedListing(rentalItemId);
    setSelectedOption(options);
  };

  const [openOrderString, setOpenOrderString] = useState<string | null>(null);

  const toggleDrawer = (newOrderString: string | null) =>
    setOpenOrderString(newOrderString);

  const onOpenOrder = (orderString: string) => {
    toggleDrawer(orderString);
  };

  const keeneeOrderNumber = useMemo(() => {
    return openOrderString?.startsWith("K.")
      ? parseInt(openOrderString.split(".")[1], 10)
      : null;
  }, [openOrderString]);

  // Only display the loading indicator on the first load
  if (stockUnitsLoading) {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          pt: 8,
          mt: -8,
          zIndex: 1,
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <DataSectionLoading />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        pt: 8,
        mt: -8,
        zIndex: 1,
        boxSizing: "border-box",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        xs={12}
        sx={{
          display: "flex",
          flexGrow: 0,
          maxHeight: "120px",
          borderColor: "rgba(0, 0, 0, 0.12)!important",
          borderBottom: 1,
        }}
      >
        <Box
          sx={{
            width: "290px",

            display: "flex",
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyItems: "stretch",
                height: "100%",
                pl: 2,
                pr: 2,
                backgroundColor: "white",
              }}
            >
              <ListingOptionSelector
                allStockUnits={allStockUnits}
                onListingUnitSelected={handleListingUnitSelected}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "80vw", flexGrow: 1, display: "flex", pl: 2, pr: 2 }}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={6}>
              <AvailabilityMonthSelector
                onMonthSelected={handleMonthSelected}
                monthYear={monthYear}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <AvailabilityActions
                onChangeShowStock={(currentShowStock) =>
                  setShowStock(currentShowStock)
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        xs={12}
        sx={{
          display: "flex",
          position: "relative",
          width: "100%",
          alignItems: "stretch",
          justifyContent: "stretch",
          justifyItems: "stretch",
          alignContent: "stretch",
        }}
      >
        <AvailabilityCalendar
          showStock={showStock}
          startMonth={monthYear}
          allStockUnits={allStockUnits}
          selectedListing={selectedListing}
          selectedOption={selectedOption}
          onOpenOrder={onOpenOrder}
        />
      </Grid>

      <Drawer
        anchor="right"
        open={openOrderString !== null}
        onClose={() => {
          toggleDrawer(null);
        }}
      >
        {openOrderString?.startsWith("K.") && keeneeOrderNumber && (
          <VendorAnyOrderDetails
            orderNumber={keeneeOrderNumber}
            onClose={() => {
              toggleDrawer(null);
            }}
          />
        )}
        {openOrderString?.startsWith("E.") && (
          <VendorReservationDetails
            orderNumber={parseInt(openOrderString.split(".")[1], 10)}
            onClose={() => {
              toggleDrawer(null);
            }}
          />
        )}
      </Drawer>
    </Box>
  );
};
