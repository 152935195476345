import { Grid } from "@mui/material";
import { CurrentVendorOrders } from "../../components/CurrentVendorOrders";
import { PageHeader } from "../../components/PageHeader";

export function Dashboard() {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <PageHeader>Dashboard</PageHeader>
        </Grid>
        <Grid item xs={12}>
          <CurrentVendorOrders />
        </Grid>
      </Grid>
    </Grid>
  );
}
