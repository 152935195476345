import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import CssBaseline from "@mui/material/CssBaseline";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { router } from "./router";
import { keeneeTheme } from "./theme";
import { SnackbarProvider } from "notistack";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function App() {
  return (
    <Provider store={store}>
      <CssVarsProvider theme={keeneeTheme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </SnackbarProvider>
      </CssVarsProvider>
    </Provider>
  );
}

export default App;
