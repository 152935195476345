import { useLocalStorage } from "usehooks-ts";

export const useAutoRefresh = () => {
  const [autoRefresh, setAutoRefresh] = useLocalStorage("auto-refresh", true);

  return {
    autoRefresh,
    setAutoRefresh,
  };
};
