import { Box, Typography } from "@mui/material";

export const DataSectionError = () => (
  <Box
    sx={{
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography color="error">Error occurred while loading the data</Typography>
  </Box>
);
