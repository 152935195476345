import { Box, Typography } from "@mui/material";

interface ListingPricePointProps {
  cost?: string;
  period?: string;
}

export const ListingPricePoint: React.FC<ListingPricePointProps> = ({
  cost,
  period,
}) => {
  return (
    <Box>
      <Typography
        color="text.secondary"
        sx={{ fontWeight: "500", mb: -1, fontSize: 13 }}
      >
        {cost ? `$${cost}` : `-`}
      </Typography>

      {period && (
        <Typography variant="caption" color="text.secondary">
          /{period}
        </Typography>
      )}
    </Box>
  );
};
