import { useState } from "react";

export const useTabs = (defaultTab: string) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    setCurrentTab(newTab);
  };

  return {
    currentTab,
    handleChangeTab,
  };
};
