import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { VendorBooking, VendorOrder } from "../types";
import moment from "moment";
import _ from "lodash";

interface VendorOrderLineItemsTableProps {
  order: VendorOrder;
}

export const VendorOrderLineItemsTable: React.FC<
  VendorOrderLineItemsTableProps
> = ({ order }) => (
  <TableContainer
    sx={{
      borderRadius: 2,
      border: 1,
      borderColor: "divider",
    }}
  >
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Listing</TableCell>
          <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
          <TableCell>Options</TableCell>
          <TableCell>Dates</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.sortBy(order.bookings, (booking) =>
          moment(booking.bookingStartDate).toDate(),
        ).map((booking: VendorBooking) => (
          <TableRow
            key={booking.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {booking.listingTitle}
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              {booking.quantity || 1}
            </TableCell>
            <TableCell>
              {booking.selectedOptions
                ? _.map(
                    booking.selectedOptions,
                    (optionValue: string, optionName: string) => (
                      <Typography>{`${optionName}: ${optionValue}`}</Typography>
                    ),
                  )
                : "-"}
            </TableCell>
            <TableCell>
              {booking.isHourly
                ? `${moment(booking.bookingStartDate).format("MMM D")} (${booking.bookingPeriodCount} ${booking.bookingPeriodCount === 1 ? "hour" : "hours"})`
                : `${moment(booking.bookingStartDate).format(
                    "MMM D",
                  )} - ${moment(booking.bookingEndDate).format("MMM D, YYYY")}`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
